var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.noAccount
    ? _c("div", [
        _vm._v(" Do you already have a Nuvolos account? "),
        _c(
          "div",
          { staticClass: "text-center mt-4" },
          [
            _c(
              "v-btn",
              {
                staticClass: "mr-4",
                attrs: { text: "" },
                on: { click: _vm.loginAndRedirect }
              },
              [_vm._v("Yes")]
            ),
            _c(
              "v-btn",
              { attrs: { text: "" }, on: { click: _vm.setNoAccount } },
              [_vm._v("No")]
            )
          ],
          1
        )
      ])
    : _c("div", [_vm._t("default")], 2)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }