<template>
    <div v-if="!noAccount">
        Do you already have a Nuvolos account?
        <div class="text-center mt-4">
            <v-btn @click="loginAndRedirect" text class="mr-4">Yes</v-btn>
            <v-btn @click="setNoAccount" text>No</v-btn>
        </div>
    </div>
    <div v-else>
        <slot></slot>
    </div>
</template>

<script>
import store from 'store'

export default {
    data() {
        return {
            noAccount: false
        }
    },
    methods: {
        loginAndRedirect() {
            store.set('nvTarget', location.pathname)
            this.$router.push({ name: 'login' })
        },
        setNoAccount() {
            this.$data.noAccount = true
        }
    }
}
</script>
